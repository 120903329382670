import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { IconArrowLeft, IconMoon, IconSun } from '../_Catalog/icons';
import ServiceMenu from '../ServiceMenu/ServiceMenu';

const PageLayout = ({ pageName, pageClass, children, redirectBack }) => {
  const [dark, setDark] = useState(() => {
    const storedDarkMode = localStorage.getItem('dark_mode');
    return storedDarkMode ? JSON.parse(storedDarkMode) : false;
  });
  useEffect(() => {
    localStorage.setItem('dark_mode', JSON.stringify(dark));
    const body = document.body;
    if (dark) {
      body.classList.add('dark');
    } else {
      body.classList.remove('dark');
    }
  }, [dark]);

  if (!pageName || !pageClass) {
    console.error('Missing Props : pageName or pageClass');
    return <div className="text-2xl text-app-red-color text-center mt-6">Missing Props : pageName or pageClass</div>;
  }


  return (
     <div className={`${pageClass} my-3 mr-3 h-[97vh] rounded-xl bg-[#FFFFFF] relative dark:bg-custom-radial-gradient ${pageClass === 'SystemsPage' ? 'ml-3' : ''}`}>
   

      <ServiceMenu/>
      {(pageName !== 'Create operation flow' && pageName !== 'Operation Flow Details' && pageName !== 'Home') && (
        <section className="pageTitleContainer flex justify-between px-6 py-4 items-baseline">
          <h1 className="flex items-center gap-x-1 text-primary-color dark:text-primary-text-color text-3xl uppercase font-medium mb-2">
            {redirectBack && (
              <NavLink to={redirectBack} className="flex items-center">
                <button className="px-2 -ml-2.5">
                  <IconArrowLeft />
                </button>
              </NavLink>
            )}
            {pageName}
          </h1>
          <div>
            <button className="-mr-2 px-2 pb-2" onClick={() => setDark(!dark)}>
              {dark ? <IconMoon /> : <IconSun color="#dd6b20" />}
            </button>
          </div>
        </section>
      )}

      <section className="mainContent px-6 mt-2 pb-8">
        {children}
      </section>
    </div>
  );
};

export default PageLayout;
