import React, { createContext, useContext, useState } from 'react';

// Create Context
const PaginationContext = createContext();

// Custom Hook to Use Pagination Context
export const usePagination = () => useContext(PaginationContext);

// Provider Component
export const PaginationProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginationContext.Provider value={{ currentPage, setCurrentPage }}>
      {children}
    </PaginationContext.Provider>
  );
};