import React, { createContext, useContext, useState } from 'react';

// Create Context
const SelectedSystemContext = createContext();

export const useSelectedSystemContext = () => useContext(SelectedSystemContext);

// Provider Component
export const SelectedSystemProvider = ({ children }) => {
    const [selectedSystem, setSelectedSystem] = useState('SAP');

    return (
        <SelectedSystemContext.Provider value={{ selectedSystem, setSelectedSystem }}>
            {children}
        </SelectedSystemContext.Provider>
    );
};
