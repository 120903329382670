import { Navigate } from 'react-router-dom';
import useLogin from './logic/useLogin';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import InputField from '../../components/AppInputForm/InputField/InputField';
import { IconMicrosoft, IconSpinner } from '../../components/_Catalog/icons';
import logo from "../../assets/img/minotaur-logo.svg";
import useQuoteSlider from './logic/useQuoteSlider';
import img from '../../assets/img/22920.jpg'
import './LoginPage.css';

const LoginPage = () => {
  const { quotes, currentQuoteIndex, progress, handleQuoteChange } = useQuoteSlider();

  const {
    loginLoading,
    loginError,
    dispatcherToken,
    extractorToken,
    decisionToken,
    sapientToken,
    submitLogin,
    register,
    handleSubmit,
    isSubmitted,
    errors,
    isMicrosoftAzureAuthLoading,
    handleMicrosoftAzureLogin,
  } = useLogin();

  if (dispatcherToken && extractorToken && decisionToken && sapientToken) {
    return <Navigate to="/" replace />;
  }

  const buttonLabel = loginLoading ? (
    <div className="animate-spin h-5 w-5 mr-2">
      <IconSpinner />
    </div>
  ) : (
    <span>Sign in</span>
  );

  return (
    <div className="loginPage fixed inset-0 flex items-center justify-center p-4 bg-cover bg-center" /* "bg-login-page-bg" */>
      <div className="absolute inset-0 -z-10 h-full w-full bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#3D57DE_100%)]" style={{ backgroundImage: `url(${ img }) `,backgroundSize:'cover'}}></div>
      <div className="flex w-[90%] max-w-[1200px] h-[60vh] overflow-hidden rounded-xl shadow-lg">
        <div className="flex w-[50%] h-full p-3 bg-white/10 backdrop-blur-lg rounded-l-xl">
          <div className='w-full h-full p-4 bg-login-page-bg bg-cover bg-center rounded-xl'>
            <div className='bg-white/10 backdrop-blur-sm p-4 rounded-xl'>
              <h2 className="text-white text-4xl font-bold">Get Everything You Want</h2>
              <p className={`text-app-bg-gray-100 mt-4 transition-opacity duration-1000 ease-in-out font-medium`}>
                {quotes[currentQuoteIndex]}
              </p>
              <div className="flex items-center justify-center w-full gap-2 mt-4">
                {quotes.map((_, index) => (
                  <div
                    key={index}
                    className="relative w-10 h-1 bg-gray-400 rounded-full cursor-pointer"
                    onClick={() => handleQuoteChange(index)}
                  >
                    <div
                      className={`absolute top-0 left-0 h-1 ${currentQuoteIndex === index ? 'bg-white' : 'bg-gray-300'} rounded-full transition-width duration-100 ease-linear`}
                      style={{ width: `${progress[index]}%` }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white/10 backdrop-blur-sm flex flex-col justify-between w-[50%] h-full p-8 rounded-r-xl">
          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <img className="h-14 mr-2 w-auto" src={logo} alt="Minotaur" />
              <span className="text-2xl font-medium text-blue-700">
                MINO<span className="text-orange-600">TAUR</span>
              </span>
            </div>
            <div className='text-sm text-white font-medium'>The Mythical Beast of Modern Monitoring.</div>
          </div>
          <form className="space-y-3" onSubmit={handleSubmit(submitLogin)}>
            <InputField
              name="username"
              label="Username"
              placeholder="Enter username"
              error={loginError}
              errors={isSubmitted && errors?.username}
              register={register}
            />
            <InputField
              name="password"
              label="Password"
              placeholder="Enter password"
              type="password"
              error={loginError}
              errors={isSubmitted && errors?.password}
              register={register}
            />
            <div className="grid m-auto w-fit gap-2 mt-4">
              <PrimaryButton
                disabled={loginLoading}
                variant="dark"
                type="submit"
                buttonText={buttonLabel}
              />
              <PrimaryButton
                disabled={isMicrosoftAzureAuthLoading}
                variant="dark"
                type="button"
                buttonEvent={handleMicrosoftAzureLogin}
                buttonText="Sign in with Microsoft"
                iconButton={<IconMicrosoft />}
                isLoading={isMicrosoftAzureAuthLoading}
              />
            </div>
          </form>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
