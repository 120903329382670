import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Navigation from '../AppSide/components/Navigation';
import { useService } from "../../components/ServiceMenu/ServiceContext"
import AppHeader from '../AppHeader/AppHeader';
const AppLayout = ({ children }) => {
  const isDarkMode = document.body.classList.contains('dark');
  const { selectedService } = useService();
  return (
    
    <div className='flex'>
      {(selectedService !=='/dashboard' && selectedService !=='/' && selectedService !=='/user-dashboard') && (
           <Navigation />
      )}


      <div className={`app-layout h-screen w-full overflow-hidden`}>
      {(selectedService ==='/dashboard') && (
     <AppHeader />
      )}
        <ToastContainer
          autoClose={2000}
          hideProgressBar={true}
          theme={isDarkMode ? 'dark' : 'light'}
          className="mt-8"
        />
        {children}
      </div>
      {/* <AppFooter /> */}
    </div>
  );
};

export default AppLayout;